.root{
  max-width: 108px;
  margin-bottom: 9px;
}

.root > div:first-of-type{
  width: 108px; 
  height: 108px;
}

.root > div:last-of-type{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  text-align: center;
}