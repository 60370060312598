.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;
  font-size: 12px;
}

.divider {
  margin: 4px -10px;
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.header > * {
  text-transform: capitalize;
}

.values {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
}